@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot');
  src: url('./fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf') format('truetype'),
    url('./fonts/icomoon.woff') format('woff'),
    url('./fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calendas-Plus';
  src: url('./fonts/Calendas-Plus.eot');
  src: url('./fonts/Calendas-Plus.eot#iefix') format('embedded-opentype'),
    url('./fonts/Calendas-Plus.ttf') format('truetype'),
    url('./fonts/Calendas-Plus.woff') format('woff'),
    url('./fonts/Calendas-Plus.svg#Calendas-Plus') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cinzel';
  src: url('./fonts/Cinzel-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cinzel-Bold';
  src: url('./fonts/Cinzel-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/cinzel-decorative-v7-latin-regular.eot');
  src: local('Cinzel Decorative Regular'), local('CinzelDecorative-Regular'),
      url('../fonts/cinzel-decorative-v7-latin-regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/cinzel-decorative-v7-latin-regular.woff2') format('woff2'),
      url('../fonts/cinzel-decorative-v7-latin-regular.woff') format('woff'),
      url('../fonts/cinzel-decorative-v7-latin-regular.ttf') format('truetype'), 
      url('../fonts/cinzel-decorative-v7-latin-regular.svg#CinzelDecorative') format('svg'); 
} */
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
body {
  background-color: #191919;
  background-image: url('./com_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 16px;  /* every other font size should be in em-s */
  background-position: center center;
}
body.ingame {
  background-image: url('./com_ingame.jpg');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
  body {
    background-image: url('./com_bg2X.jpg');
  }
  body.ingame {
      background-image: url('./com_ingame2X.jpg');
  }
}

canvas {
  background-color: 'transparent';
}
#React-1 {
  top: 0px !important;
  left: 0px !important;
  width: 100vw !important;
  height: 100% !important;
}
label {
  color: black;
  text-align: center;
  display: block;
}

.flexGrow1 {
  flex-grow: 1;
}
.flexGrow2 {
  flex-grow: 2;
}

.fullHeight {
  height: 100%;
}

/* See: https://github.com/moroshko/react-autosuggest/#theme-optional */
.react-autosuggest__suggestions-container--open {
  position: absolute;
  background: #fff;
  padding: 1em;
  z-index: 1000;
  right: 32px;
}
.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-inline-start: 0;
}

.formikForm {
  width: 100%!important;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: '#999999'; 
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.7); 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: '#8E8F9277'; 
}
